import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Form, Input, Select, Alert } from 'antd'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useRegister } from '../authHooks'
const industries = [
  { label: 'Accounting', value: 'Accounting' },
  { label: 'Airlines/Aviation', value: 'Airlines/Aviation' },
  {
    label: 'Alternative Dispute Resolution',
    value: 'Alternative Dispute Resolution',
  },
  { label: 'Alternative Medicine', value: 'Alternative Medicine' },
  { label: 'Animation', value: 'Animation' },
  { label: 'Apparel & Fashion', value: 'Apparel & Fashion' },
  { label: 'Architecture & Planning', value: 'Architecture & Planning' },
  { label: 'Arts and Crafts', value: 'Arts and Crafts' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Aviation & Aerospace', value: 'Aviation & Aerospace' },
  { label: 'Banking', value: 'Banking' },
  { label: 'Biotechnology', value: 'Biotechnology' },
  { label: 'Broadcast Media', value: 'Broadcast Media' },
  { label: 'Building Materials', value: 'Building Materials' },
  {
    label: 'Business Supplies and Equipment',
    value: 'Business Supplies and Equipment',
  },
  { label: 'Capital Markets', value: 'Capital Markets' },
  { label: 'Chemicals', value: 'Chemicals' },
  {
    label: 'Civic & Social Organization',
    value: 'Civic & Social Organization',
  },
  { label: 'Civil Engineering', value: 'Civil Engineering' },
  { label: 'Commercial Real Estate', value: 'Commercial Real Estate' },
  {
    label: 'Computer & Network Security',
    value: 'Computer & Network Security',
  },
  { label: 'Computer Games', value: 'Computer Games' },
  { label: 'Computer Hardware', value: 'Computer Hardware' },
  { label: 'Computer Networking', value: 'Computer Networking' },
  { label: 'Computer Software', value: 'Computer Software' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Consumer Electronics', value: 'Consumer Electronics' },
  { label: 'Consumer Goods', value: 'Consumer Goods' },
  { label: 'Consumer Services', value: 'Consumer Services' },
  { label: 'Cosmetics', value: 'Cosmetics' },
  { label: 'Dairy', value: 'Dairy' },
  { label: 'Defense & Space', value: 'Defense & Space' },
  { label: 'Design', value: 'Design' },
  { label: 'Education Management', value: 'Education Management' },
  { label: 'ELearning', value: 'ELearning' },
  {
    label: 'Electrical/Electronic Manufacturing',
    value: 'Electrical/Electronic Manufacturing',
  },
  { label: 'Entertainment', value: 'Entertainment' },
  { label: 'Environmental Services', value: 'Environmental Services' },
  { label: 'Events Services', value: 'Events Services' },
  { label: 'Executive Office', value: 'Executive Office' },
  { label: 'Facilities Services', value: 'Facilities Services' },
  { label: 'Farming', value: 'Farming' },
  { label: 'Financial Services', value: 'Financial Services' },
  { label: 'Fine Art', value: 'Fine Art' },
  { label: 'Fishery', value: 'Fishery' },
  { label: 'Food & Beverages', value: 'Food & Beverages' },
  { label: 'Food Production', value: 'Food Production' },
  { label: 'Fund-Raising', value: 'Fund-Raising' },
  { label: 'Furniture', value: 'Furniture' },
  { label: 'Gambling & Casinos', value: 'Gambling & Casinos' },
  {
    label: 'Glass, Ceramics & Concrete',
    value: 'Glass, Ceramics & Concrete',
  },
  { label: 'Government Administration', value: 'Government Administration' },
  { label: 'Government Relations', value: 'Government Relations' },
  { label: 'Graphic Design', value: 'Graphic Design' },
  {
    label: 'Health, Wellness and Fitness',
    value: 'Health, Wellness and Fitness',
  },
  { label: 'Higher Education', value: 'Higher Education' },
  { label: 'Hospital & Health Care', value: 'Hospital & Health Care' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Human Resources', value: 'Human Resources' },
  { label: 'Import and Export', value: 'Import and Export' },
  {
    label: 'Individual & Family Services',
    value: 'Individual & Family Services',
  },
  { label: 'Industrial Automation', value: 'Industrial Automation' },
  { label: 'Information Services', value: 'Information Services' },
  {
    label: 'Information Technology and Services',
    value: 'Information Technology and Services',
  },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'International Affairs', value: 'International Affairs' },
  {
    label: 'International Trade and Development',
    value: 'International Trade and Development',
  },
  { label: 'Internet', value: 'Internet' },
  { label: 'Investment Banking', value: 'Investment Banking' },
  { label: 'Investment Management', value: 'Investment Management' },
  { label: 'Judiciary', value: 'Judiciary' },
  { label: 'Law Enforcement', value: 'Law Enforcement' },
  { label: 'Law Practice', value: 'Law Practice' },
  { label: 'Legal Services', value: 'Legal Services' },
  { label: 'Legislative Office', value: 'Legislative Office' },
  { label: 'Leisure, Travel & Tourism', value: 'Leisure, Travel & Tourism' },
  { label: 'Libraries', value: 'Libraries' },
  {
    label: 'Logistics and Supply Chain',
    value: 'Logistics and Supply Chain',
  },
  { label: 'Luxury Goods & Jewelry', value: 'Luxury Goods & Jewelry' },
  { label: 'Machinery', value: 'Machinery' },
  { label: 'Management Consulting', value: 'Management Consulting' },
  { label: 'Maritime', value: 'Maritime' },
  { label: 'Market Research', value: 'Market Research' },
  { label: 'Marketing and Advertising', value: 'Marketing and Advertising' },
  {
    label: 'Mechanical or Industrial Engineering',
    value: 'Mechanical or Industrial Engineering',
  },
  { label: 'Media Production', value: 'Media Production' },
  { label: 'Medical Devices', value: 'Medical Devices' },
  { label: 'Medical Practice', value: 'Medical Practice' },
  { label: 'Mental Health Care', value: 'Mental Health Care' },
  { label: 'Military', value: 'Military' },
  { label: 'Mining & Metals', value: 'Mining & Metals' },
  { label: 'Mobile Games', value: 'Mobile Games' },
  { label: 'Motion Pictures and Film', value: 'Motion Pictures and Film' },
  { label: 'Museums and Institutions', value: 'Museums and Institutions' },
  { label: 'Music', value: 'Music' },
  { label: 'Nanotechnology', value: 'Nanotechnology' },
  { label: 'Newspapers', value: 'Newspapers' },
  {
    label: 'Non-Profit Organization Management',
    value: 'Non-Profit Organization Management',
  },
  { label: 'Oil & Energy', value: 'Oil & Energy' },
  { label: 'Online Media', value: 'Online Media' },
  { label: 'Outsourcing/Offshoring', value: 'Outsourcing/Offshoring' },
  { label: 'Package/Freight Delivery', value: 'Package/Freight Delivery' },
  { label: 'Packaging and Containers', value: 'Packaging and Containers' },
  { label: 'Paper & Forest Products', value: 'Paper & Forest Products' },
  { label: 'Performing Arts', value: 'Performing Arts' },
  { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
  { label: 'Philanthropy', value: 'Philanthropy' },
  { label: 'Photography', value: 'Photography' },
  { label: 'Plastics', value: 'Plastics' },
  { label: 'Political Organization', value: 'Political Organization' },
  {
    label: 'Primary/Secondary Education',
    value: 'Primary/Secondary Education',
  },
  { label: 'Printing', value: 'Printing' },
  {
    label: 'Professional Training & Coaching',
    value: 'Professional Training & Coaching',
  },
  { label: 'Program Development', value: 'Program Development' },
  { label: 'Public Policy', value: 'Public Policy' },
  {
    label: 'Public Relations and Communications',
    value: 'Public Relations and Communications',
  },
  { label: 'Public Safety', value: 'Public Safety' },
  { label: 'Publishing', value: 'Publishing' },
  { label: 'Railroad Manufacture', value: 'Railroad Manufacture' },
  { label: 'Ranching', value: 'Ranching' },
  { label: 'Real Estate', value: 'Real Estate' },
  {
    label: 'Recreational Facilities and Services',
    value: 'Recreational Facilities and Services',
  },
  { label: 'Religious Institutions', value: 'Religious Institutions' },
  { label: 'Renewables & Environment', value: 'Renewables & Environment' },
  { label: 'Research', value: 'Research' },
  { label: 'Restaurants', value: 'Restaurants' },
  { label: 'Retail', value: 'Retail' },
  {
    label: 'Security and Investigations',
    value: 'Security and Investigations',
  },
  { label: 'Semiconductors', value: 'Semiconductors' },
  { label: 'Shipbuilding', value: 'Shipbuilding' },
  { label: 'Sporting Goods', value: 'Sporting Goods' },
  { label: 'Sports', value: 'Sports' },
  { label: 'Staffing and Recruiting', value: 'Staffing and Recruiting' },
  { label: 'Supermarkets', value: 'Supermarkets' },
  { label: 'Telecommunications', value: 'Telecommunications' },
  { label: 'Textiles', value: 'Textiles' },
  { label: 'Think Tanks', value: 'Think Tanks' },
  { label: 'Tobacco', value: 'Tobacco' },
  {
    label: 'Translation and Localization',
    value: 'Translation and Localization',
  },
  {
    label: 'Transportation/Trucking/Railroad',
    value: 'Transportation/Trucking/Railroad',
  },
  { label: 'Utilities', value: 'Utilities' },
  {
    label: 'Venture Capital & Private Equity',
    value: 'Venture Capital & Private Equity',
  },
  { label: 'Veterinary', value: 'Veterinary' },
  { label: 'Warehousing', value: 'Warehousing' },
  { label: 'Wholesale', value: 'Wholesale' },
  { label: 'Wine and Spirits', value: 'Wine and Spirits' },
  { label: 'Wireless', value: 'Wireless' },
  { label: 'Writing and Editing', value: 'Writing and Editing' },
]

export default function RegisterForm({ onFinish, onFinishFailed, onChange }) {
  const [register] = useRegister()
  const [isRegistering, setIsRegistering] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (window.Rewardful) window.Rewardful.Forms.attach()
  }, [])

  const { executeRecaptcha } = useGoogleReCaptcha()

  const fields = [
    {
      name: 'fullName',
      placeholder: 'Full Name',
      rules: [
        {
          required: true,
          message: 'Please enter your full name',
        },
      ],
    },
    {
      name: 'email',
      placeholder: 'Email Address',
      rules: [
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          required: true,
          message: 'Please input your E-mail!',
        },
      ],
    },
    {
      name: 'password',
      placeholder: 'Password',
      rules: [{ required: true, message: 'Please enter your desired password' }],
      content: <Input.Password placeholder='Password' size='large' />,
    },
    {
      name: 'industry',
      content: <Select options={industries} size='large' placeholder='Industry' showSearch={true} />,
    },
    {
      name: ['preferences', 'emailOptIn'],
      valuePropName: 'checked',
      initialValue: false,
      content: <Checkbox>Notify me about updates and promotions.</Checkbox>,
    },
  ]

  async function handleFinish(args = {}) {
    try {
      setIsRegistering(true)
      // Split full name by space
      const splitName = args?.fullName.split(' ')

      const data = {
        name: {
          givenName: splitName.shift(),
          familyName: splitName.join(' '),
        },
        email: args.email,
        password: args.password,
        rewardful: window?.Rewardful?.referral,
      }

      if (executeRecaptcha) data.captcha = await executeRecaptcha()

      await register(data)

      if (error) throw error

      setIsRegistering(false)
      return onFinish(data)
    } catch (error) {
      setIsRegistering(false)
      const message = error?.data?.message || error.message
      setError(message)
    }
  }

  return (
    <Form
      onFinish={handleFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onChange}
      data-rewardful='true'
      validateTrigger={['onSubmit']}
    >
      {error && <Alert type='error' message={error || 'Something went wrong'} className='mb-3' />}

      {fields.map(({ name, rules, content, placeholder, ...rest }) => (
        <Form.Item name={name} rules={rules} key={name} {...rest}>
          {content ? content : <Input placeholder={placeholder} size='large' />}
        </Form.Item>
      ))}
      {/* SUBMIT BUTTON */}
      <Form.Item>
        <Button type='primary' htmlType='submit' block size='large' loading={isRegistering}>
          Create Account
        </Button>
      </Form.Item>
    </Form>
  )
}
